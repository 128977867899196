<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>

<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    bannerList,
    saveBanner,
    changeTop,
    changeView,
    deleteBanner
  } from "@/api/web/banner.js";
  import {
    getUploadLimit,
    checkFile,
    uploadFiles
  } from "@/api/common/public.js";


  /**
   * banner管理
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "网站banner ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        query: {
          keyword: "",
          bannerType: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20
        },
        checked: true,
        editButton: false,
        showmodal: false,
        list: [],
        typeList: [],
        bannerForm: {
          bannerId: "",
          bannerTitle: "",
          bannerInfo: "",
          bannerType: "",
          bannerUrl: "",
          imageUrl: ""
        },
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0,
        // 文件上传
        uploadStatus: "",
        uploadModule: "WebBanner",
        uploadLimit: {
          ext: "",
          size: 0
        },
      };
    },
    mounted() {
      getUploadLimit({
        module: this.uploadModule
      }).then(res => {
        if (res.status) {
          this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
          this.uploadLimit["size"] = res.data.sizeLimit;
        }
      });
      this.getList();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询文件列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        bannerList(this.query).then(res => {
          if (res.status) {
            let tempList = [];
            for (let item of res.data) {
              item.isTop = item.isTop == '1' ? true : false
              item.isView = item.isView == '1' ? true : false
              tempList.push(item);
            }
            this.list = tempList
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      /** 修改置顶值 */
      updateTop(row) {
        changeTop({
          bannerId: row.bannerId,
          isTop: row.isTop ? '1' : '0'
        }).then((res) => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: "修改成功"
            });
          }
        })
      },
      /** 修改置顶值 */
      updateView(row) {
        changeView({
          bannerId: row.bannerId,
          isView: row.isView ? '1' : '0'
        }).then((res) => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: "修改成功"
            });
          }
        })
      },
      /** 删除文件 */
      deleteBanner(bannerId, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteBanner(bannerId).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.getList()
          })
        })
      },
      /** 初始化编辑文件 */
      initEditForm(row) {
        this.showmodal = true
        this.editButton = true
        this.bannerForm = {
          bannerId: row.bannerId,
          bannerTitle: row.bannerTitle,
          bannerInfo: row.bannerInfo,
          bannerType: row.bannerType,
          bannerUrl: row.bannerUrl,
          imageUrl: row.imageUrl
        };
      },
      /** 上传文件 */
      uploadFile(file) {
        var _this = this;
        file = file.raw;
        if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
          _this.uploadStatus = "loading";
          uploadFiles(_this.uploadModule, file).then(res => {
            if (res.status) {
              var file = res.data[0];
              if (file.status) {
                _this.bannerForm.imageUrl = file.path;
                _this.$message({
                  type: "success",
                  message: "上传成功"
                });
              } else {
                _this.$message({
                  type: "error",
                  message: file.error
                });
              }
            }
            _this.uploadStatus = "";
          });
        }
      },
      /** 保存文件 */
      saveBannerForm() {
        if (this.checkForm()) {
          // 提交
          saveBanner(this.bannerForm).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '提交成功!'
              });
              this.getList();
              this.cleanForm();
            }
          })
        }
      },
      /** 校验数据 */
      checkForm() {
        if (this.bannerForm.bannerTitle == "") {
          this.checkAlert("标题不能为空")
          return false;
        }
        if (this.bannerForm.bannerInfo == "") {
          this.checkAlert("介绍不能为空")
          return false;
        }
        if (this.bannerForm.bannerType == "") {
          this.checkAlert("类型不能为空")
          return false;
        }
        return true;
      },
      /** 校验提醒 */
      checkAlert(message) {
        this.$message({
          type: 'error',
          message: message
        });
      },
      /** 清空表单 */
      cleanForm() {
        this.showmodal = false
        Object.keys(this.bannerForm).forEach((key) => (this.bannerForm[key] = ''))
        this.editButton = false;
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 check-table-top">
              <form class="flexList" style="flex: 1;">
                <input placeholder="输入关键字" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
                <b-button variant="info" class="btn flexList mr-2 condition btn-outline-info" @click="showmodal=true"><i
                    class="iconfont icon-plus-circle1 mr-2"></i>添加BANNER</b-button>
              </form>
            </div>
            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:4%"> 序号</th>
                    <th style="width:6%">图片类型</th>
                    <th>图片标题</th>
                    <th style="width:20%">所属专题</th>
                    <th style="width:25%">图片说明</th>
                    <th style="width: 8%;">发布人</th>
                    <th style="width: 7%;">发布时间</th>
                    <th style="width:2%">置顶</th>
                    <th style="width:2%">可见</th>
                    <th style="width:3%">编辑</th>
                    <th style="width:3%">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.bannerType}} </td>
                    <td>{{obj.bannerTitle}}</td>
                    <td>{{obj.bannerUrl}}</td>
                    <td>{{obj.bannerInfo}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{obj.createTime != null ? obj.createTime.substring(0,10) : ""}}</td>
                    <td class="tab-icon">
                      <b-form-checkbox v-model="obj.isTop" @change="updateTop(obj)" switch class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon">
                      <b-form-checkbox v-model="obj.isView" @change="updateView(obj)" switch class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two" @click="initEditForm(obj)"></i></td>
                    <td class="tab-icon" @click="deleteBanner(obj.bannerId,obj.bannerTitle)"><i
                        class="iconfont icon-riLine-delete-bin-line"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="perPage" layout="total, sizes,jumper, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal id="uploadRule" v-model="showmodal" centered title="网站BANNER" size="lg" title-class="font-18" hide-footer>
      <form class="w-100  p-3">
        <div class="form-group flexList">
          <div class="operation-text">图片标题：</div>
          <input type="text" name="bannerTitle" id="bannerTitle" v-model="bannerForm.bannerTitle"
            class="form-control w-40 h30 mr-2">
        </div>
        <div class="form-group flexList">
          <div class="operation-text">图片类型：</div>
          <select name="bannerType" id="bannerType" class="form-control w-40 form-select mr-2"
            v-model="bannerForm.bannerType">
            <option value="index上部">index上部</option>
            <option value="index中部">index中部</option>
            <option value="index底部">index底部</option>
            <option value="其他">其他</option>
          </select>

        </div>
        <div class="form-group flexList">
          <div class="operation-text">图片说明：</div>
          <textarea name="bannerInfo" id="bannerInfo" rows="6" v-model="bannerForm.bannerInfo" class="form-control"
            style="width: 81%;"></textarea>
        </div>

        <div class="form-group flexList">
          <div class="operation-text">所属专题：</div>
          <input type="text" name="bannerUrl" id="bannerUrl" v-model="bannerForm.bannerUrl"
            class="form-control w-40 h30 mr-2" value="http://">
          <span>例如： http://www.baidu.com</span>
        </div>
        <div class="form-group flexList">
          <div class="operation-text">图片尺寸：</div>
          <span>index上部：444*296px；index中部：1200*120px；index底部：200*55px</span>
        </div>
        <div class="form-group mb-2 flexList">
          <div class="operation-text">上传图片：</div>
          <a v-if="bannerForm.imageUrl" class="colorA mr-2" :href="BASE_FILE_URL + bannerForm.imageUrl" target="_blank"
            style="display:block;">查看上传文件
          </a>
          <!--<el-form-item label="" prop="imageUrl" style="width:50%;">-->
          <el-upload class="upload-demo" ref="upload" action="" :on-change="uploadFile" :show-file-list="false"
            :accept="uploadLimit.ext" :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">
              <span name="default" v-if="uploadStatus != 'loading'">选取文件</span>
              <span v-if="uploadStatus == 'loading'">上传中<i class="el-icon-loading"></i></span>
            </el-button>

          </el-upload>

        </div>
        <div class=" flexList">
          <div style="width: 10%;"></div>
          <el-tag type="danger">
            支持上传{{ uploadLimit.ext }}文件，且不超过{{ uploadLimit.size/1024/1024 }}M</el-tag>
        </div>
        <div class="mt-3 text-center">
          <button type="button" class="btn btn-info h30 w-sm mr-2" @click="saveBannerForm">
            <span v-if="editButton">保存banner</span>
            <span v-if="!editButton">新增banner</span>
          </button>
          <button type="button" class="btn btn-secondary h30 w-sm " @click="cleanForm()">取消
          </button>
        </div>
      </form>
    </b-modal>

    <!-- 弹窗结束 -->
  </Layout>
</template>
