import { getRequest, postRequest, downloadRequest, patchRequest, deleteRequest } from "@/libs/axios";

// ------------------------banner管理-----------------------
// banner列表
export function bannerList(params) {
    return getRequest("/yethan/web/banner/listPage", params);
}
// banner修改置顶状态
export function changeTop(params) {
    return patchRequest("/yethan/web/banner/changeTop", params);
}
// banner修改可见状态
export function changeView(params) {
    return patchRequest("/yethan/web/banner/changeView", params);
}
// 删除banner
export function deleteBanner(sid) {
    return deleteRequest("/yethan/web/banner/"+sid);
}
// 保存banner
export function saveBanner(params) {
    return postRequest("/yethan/web/banner", params);
}
